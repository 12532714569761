import { Observable, Subject, throwError } from 'rxjs';

import { DriverDataErrorLogConstants } from 'src/app/shared/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';
import { saveAs } from 'file-saver';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
class ToastMessage {
  description: string;
  type: string;
  timeout: any;
  showClose: any;
  style: any;

  fromJSON(json) {
    for (const propName in json) {
      if (propName !== undefined) {
        this[propName] = json[propName];
      }
    }
    return this;
  }
}

@Injectable()
export class ToasterDataService {
  private toastMessages = new Subject<any>();
  constructor() {

  }

  Add(message: any) {
    let toastMessage = new ToastMessage();
    toastMessage = toastMessage.fromJSON(message);
    this.toastMessages.next(toastMessage);
  }

  AddWithDownloadLink(message: any, typeMessage = DriverDataErrorLogConstants.downloadLinkLabel,
    downloadLength = DriverDataErrorLogConstants.showErrorMessageLength) {
    // TODO
    let old_messgae = '';
    let download_template = '';
    const currentTimeMilliseconds = new Date().getMilliseconds();
    const downloadLinkTemplate =
      '<a class="downloadErrorLog_' +
      currentTimeMilliseconds +
      `">${typeMessage}</a>`;
    const downloadLinkClass = '.downloadErrorLog_' + currentTimeMilliseconds;
    const isDownload =
      message.description.length >
      downloadLength &&
      message.type === 'error';
    if (isDownload) {
      download_template = downloadLinkTemplate;
      old_messgae = message.downloadFileString;
      message.description =
        message.download_prepend_message + download_template;
    }

    let toastMessage = new ToastMessage();
    toastMessage = toastMessage.fromJSON(message);
    this.toastMessages.next(toastMessage);
    if (isDownload) {
      // Download As csv
      $(document).ready(function () {
        $(downloadLinkClass).on('click', function (e) {
          const filename = DriverDataErrorLogConstants.fileName;
          const blob = new Blob([old_messgae], { type: 'text/plain' });
          saveAs(blob, filename);
        });
      });
    }
  }

  SubscribeMessages(): Observable<any> {
    return this.toastMessages.asObservable();
  }

  ClearMessages() {
    this.toastMessages.next();
  }

  MessageToToaster(
    message: any,
    type: any,
    timeout: any = 10000,
    showClose: any = true
  ) {
    const toast = {
      description: message,
      type: type,
      timeout: timeout,
      showClose: showClose,
    };
    this.Add(toast);
  }
  addMessageToToaster(
    message: any,
    type: any = ToasterTypeConstants.ERROR,
    timeout: any = 10000,
    showClose: any = true
  ) {
    const toast = {
      description: message,
      type: type,
      timeout: timeout,
      showClose: showClose,
    };
    this.Add(toast);
    console.log(message);
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      const toast = {
        description: error.error.message,
        type: 'error',
        timeout: 10000,
        showClose: true,
      };
      this.Add(toast);

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError(error.statusText);
  }
}
