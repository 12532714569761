import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import {
  EditableTableService,
  FilterOpenService,
} from './revo-core/table-column-filters/table-column-filters.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { AppRoutingModule } from './/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieModule } from 'ngx-cookie';
import { CustomLoadingOverlayComponent } from './custom-loading-overlay/custom-loading-overlay.component';
import { DatePipe } from '@angular/common';
import { DomSanitizerPipe } from './shared/custom-pipes/dom-sanitizer-pipe';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RdhInterceptorService } from './RdhInterceptor';
import { RevoCoreModule } from './revo-core/revo-core.module';
import { ToasterDataService } from './revo-core/toaster-service/toaster-data.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZsFilterService } from './revo-core/zs-multi-select-dropdown/zs-filter.service';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCardModule } from 'ng-zorro-antd/card';

export function initConfig(config: AppConfig) {
  return () => config.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, CustomLoadingOverlayComponent],
  imports: [
    BrowserModule,
    NzModalModule,
    AppRoutingModule,
    DragDropModule,
    HttpClientModule,
    NzIconModule,
    NzButtonModule,
    FormsModule,
    RevoCoreModule,
    NzPopoverModule,
    NzTableModule,
    NzInputModule,
    NzSkeletonModule,
    NzTabsModule,
    NzCardModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppConfig,
    ToasterDataService,
    EditableTableService,
    ZsFilterService,
    FilterOpenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RdhInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    DatePipe,
    DomSanitizerPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
