import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import TimePicker from '../zsui-component-extensions/customTimePicker.m.js';
TimePicker.register('time-picker', 'p');

@Component({
  selector: 'app-zs-time-picker',
  templateUrl: './zs-time-picker.component.html',
  styleUrls: ['./zs-time-picker.component.less']
})
export class ZsTimePickerComponent implements OnInit {
  @Input() selectedTimeInput: any;
  @Input() isDisabled: false;
  @Input() label: string;
  @Input() isFormat24: true;
  @Input() customStyle = {};
  @Output() selectedTimeInputChange = new EventEmitter();
  @ViewChild('zsTimePicker') zsTimePicker: ElementRef;
  step = 60;
  time = '00:00';
  constructor() { }

  ngOnInit() {
    if (this.selectedTimeInput === undefined || this.selectedTimeInput === '') {
      this.time = '00:00';
    } else {
      this.time = this.selectedTimeInput;
    }
    this.zsTimePicker.nativeElement.style = 'width:3em;';
  }
  onChange(event) {
    this.time = this.zsTimePicker.nativeElement.value;
    this.selectedTimeInputChange.emit(this.time);
  }
  beforeOpen(event) {
    this.zsTimePicker.nativeElement.menuContainer.style.width =
    this.zsTimePicker.nativeElement.fieldContainer.offsetWidth + 'px';
    this.zsTimePicker.nativeElement.menuContainer.style.marginTop = 
    this.zsTimePicker.nativeElement.inputElement.getBoundingClientRect().height + 'px';
  }
}



