import { Directive, AfterViewInit, ElementRef } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[appAccordion]'
})
export class AccordionDirective implements AfterViewInit {

  element: any;
  constructor(Element: ElementRef) {
    this.element = Element.nativeElement;
  }

  ngAfterViewInit(): void {
    this.element.classList.add('zs-accordion');
    this.element.classList.add('zs-display-block');
    let x = $(this.element.querySelector('.accordion-header'));
    let accordionHeader = this.element.querySelector('.accordion-header');
    accordionHeader.classList.add('zs-position-relative');
    let accordionBody = this.element.querySelector('.accordion-body');
  }

}
