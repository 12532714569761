export const DdDQMDataTypeConstants = {
    INT: 'Integer',
    INTEGER: 'Integer',
    DECIMAL: 'Decimal',
    FLOAT: 'Float',
    DOUBLE: 'Double',
    BIGINT: 'Bigint',
    BOOLEAN: 'Boolean'
};

export const DdDqmConstants = {
    MIN: 'Min',
    MAX: 'Max',
    NOT_NULL: 'Not_Null',
    LENGTH: 'Length',
    GREATER_THAN: 'Greater_Than',
    LESS_THAN: 'Less_Than',
    TYPE: 'Type',
    TIMESTAMP: 'Timestamp',
    INCLUSION: 'Inclusion',
    EXCLUSION: 'Exclusion',
    RANGE: 'Range',
    EQUAL_TO: 'Equal_To',
    NOT_EQUAL_TO: 'Not_Equal_To',
    GREATER_THAN_EQUAL_TO: 'Greater_Than_Equal_To',
    LESS_THAN_EQUAL_TO: 'Less_Than_Equal_To'
};

