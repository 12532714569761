import { HighchartsTypes } from './chart-constants';
export class ChartConfig {
    chartType: string;
    title: string;
    seriesName: string;
    xCategories: any;
    yCategories: any;
    tooltip: any;
    colorAxis: any;
    enabledDataLabels: boolean;
    dataFormatter: any;
    color: string;
    constructor(chartType = null, title = null, seriesName = null,
         xCategories= null, yCategories = null, tooltip = null, colorAxis = null,
         enabledDataLabels = false, dataFormatter = null, color = null) {
        this.chartType = chartType;
        this.title = title;
        this.seriesName = seriesName;
        this.xCategories = xCategories;
        this.yCategories = yCategories;
        this.tooltip = tooltip;
        this.colorAxis = colorAxis;
        this.enabledDataLabels = enabledDataLabels;
        this.dataFormatter = dataFormatter;
        this.color = color;
    }
}

class ChartType {
    type: string;
    constructor(type) {
        this.type = type;
    }
}

class ChartTitle {
    text: string;
    constructor() {
        this.text = '';
    }
}

class XAxis {
    categories: Array<any>;
    constructor() {
    }
}

class YAxis {
    categories: Array<any>;
    title: ChartTitle;
    constructor() {
        this.title = new ChartTitle();
    }
}
class DataLabelStyle {
    textOutline: boolean;
    constructor() {
        this.textOutline = false;
    }
}
class DataLabels {
    enabled: boolean;
    style: DataLabelStyle;
    constructor() {
        this.enabled = false;
        this.style = new DataLabelStyle();
    }

}

class Data {
    name: string;
    data: Array<any>;
    showInLegend: boolean;
    style: any;
    dataLabels: DataLabels;
    color: string;
    constructor(data) {
        this.data = data;
        this.showInLegend = false;
        this.style = { textOutline: false };
        this.dataLabels = new DataLabels();
        this.color = '';
    }
}

class Credits {
    enabled: boolean;
    constructor() {
        this.enabled = false;
    }
}

class DataLabelsFormatter {
    dataLabels: any;
    constructor() {
        this.dataLabels = null;
    }
}

class PlotOptions {
    series: DataLabelsFormatter;
    constructor() {
        this.series = new DataLabelsFormatter();
    }
}

abstract class Chart {
    chart: ChartType;
    title: ChartTitle;
    xAxis: XAxis;
    yAxis: YAxis;
    series: Array<Data>;
    credits: Credits;
    tooltip: any;
    plotOptions: PlotOptions;
    constructor(type) {
        this.chart = new ChartType(type);
        this.title = new ChartTitle();
        this.xAxis = new XAxis();
        this.yAxis = new YAxis();
        this.series = [];
        this.credits = new Credits();
        this.tooltip = {
          formatter: function(tooltip) {
            return tooltip.defaultFormatter.call(this, tooltip);
          }
         };
        this.plotOptions = new PlotOptions();
    }

    public getChartTooltip() {
        return this.tooltip;
    }
    public getDataLabelsFormatter() {
        return this.plotOptions.series.dataLabels;
    }

    public abstract setChartConfig(config, data): void;
}

export class SingleLineChart extends Chart {
    constructor() {
        super(HighchartsTypes.SPLINE);
    }

    setChartConfig(config, data) {
        let series = new Data(data);
        if (config.title) {
            this.title.text = config.title;
        }
        if (config.xCategories) {
            this.xAxis.categories = config.xCategories;
        }
        if (config.seriesName) {
            series.name = config.seriesName;
        }
        if (config.tooltip) {
            this.tooltip = config.tooltip;
        }
        if (config.color) {
            series.color = config.color;
        }
        this.series.push(series);
    }

}

class HeatMapChart extends Chart {
    colorAxis: {};
    constructor() {
        super(HighchartsTypes.HEATMAP);
    }

    setChartConfig(config, data) {
        let series = new Data(data);
        if (config.title) {
            this.title.text = config.title;
        }
        if (config.xCategories) {
            this.xAxis.categories = config.xCategories;
        }
        if (config.yCategories) {
            this.yAxis.categories = config.yCategories;
        }
        if (config.seriesName) {
            series.name = config.seriesName;
        }
        if (config.tooltip) {
            this.tooltip = config.tooltip;
        }
        if (config.colorAxis) {
            this.colorAxis = config.colorAxis;
        }
        if (config.enabledDataLabels) {
            series.dataLabels.enabled = config.enabledDataLabels;
        }
        if (config.dataFormatter) {
            this.plotOptions.series.dataLabels = config.dataFormatter;
        }
        this.series.push(series);
    }

}

class ColumnChart extends Chart {
    constructor() {
        super(HighchartsTypes.COLUMN);
    }

    setChartConfig(config, data) {
        let series = new Data(data);
        if (config.title) {
            this.title.text = config.title;
        }
        if (config.xCategories) {
            this.xAxis.categories = config.xCategories;
        }
        if (config.seriesName) {
            series.name = config.seriesName;
        }
        if (config.tooltip) {
            this.tooltip = config.tooltip;
        }
        this.series.push(series);
    }

}

abstract class ChartCreator {
    public abstract factoryMethod(): Chart;

    public setChartConfig(config, data):  Chart {
        const chart = this.factoryMethod();
        chart.setChartConfig(config, data);
        return chart;
    }
}

export class SingleLineChartCreator extends ChartCreator {
    public factoryMethod(): Chart {
        return new SingleLineChart();
    }
}

export class HeatMapCreator extends ChartCreator {
    public factoryMethod(): Chart {
        return new HeatMapChart();
    }
}

export class ColumnChartCreator extends ChartCreator {
    public factoryMethod(): Chart {
        return new ColumnChart();
    }
}
