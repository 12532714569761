export const ChartNames = {
    LINE_GRAPH: 'LineGraph',
    HEAT_MAP_GRAPH: 'HeatMapGraph',
    COLUMN_GRAPH: 'ColumnGraph'
};

export const HighchartsTypes = {
    SPLINE: 'spline',
    HEATMAP: 'heatmap',
    COLUMN: 'column'
};