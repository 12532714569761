import TimePicker from '../../../../node_modules/zsui/src/timePicker/timePicker.m.js';

export default class CustomTimePicker extends TimePicker {
	constructor(...args) {
		const _ = super(...args);
		return _;
	}

  optionClick(event) {
		event.preventDefault();
        var active = this.menuContainer.querySelector("[active]");
        if (active) {
            active.removeAttribute("attr");
        }
        event.target.setAttribute("active", "");
        this.value = event.target.innerText;
        this.closeMenu(event);
        event.stopPropagation();
    }
}
