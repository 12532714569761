import { Component, OnInit, ElementRef, Input, EventEmitter, Output, HostBinding, OnDestroy, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';
import { FormValidationService } from '../form-validation-service/form-validation-service';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { DateUtils } from '../../shared/utils/date-utils';
import DatePicker from '../zsui-component-extensions/customDatePicker.m.js';
DatePicker.register('zs-data-picker', 'p');

@Component({
  selector: 'app-zs-date-picker',
  templateUrl: './zs-date-picker.component.html',
  styleUrls: ['./zs-date-picker.component.less']
})

export class ZsDatePickerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() selectedDate: any;
  @Input() isDisabled: false;
  @Input() minDate: any;
  @Input() touched: boolean;
  @Input() validationFunction: Function;
  @Input() formValidationCallbackFunction: Function;
  @Input() label: string;
  @Input() flippable: false;
  @Input() customStyle = {};
  @Input() isClearVisible: false;
  @Input() setDefaultDate: true;

  @Output() selectedDateChange = new EventEmitter();
  @Output() errorStateChange = new EventEmitter();

  @Input() errorState: boolean;

  @ViewChild('zsDatePicker') zsDatePicker: ElementRef;

  formValidationSubscription: Subscription;

  @HostBinding('class.zs-error') public inputClass = '';
  errorClass = 'zs-error';

  selectedDateValue: Date;
  minDateValue: Date;
  errorMessage: string;
  environment: EnvironmentConfig;

  constructor(
    private elementRef: ElementRef,
    public datepipe: DatePipe,
    private _formValidationService: FormValidationService,
    private config: AppConfig,
    private ddTransform: DateUtils
  ) {
    this.environment = this.config.getConfigObject();
  }

  ngAfterViewInit() {
    this.zsDatePicker.nativeElement.dateToString = this.dateToString.bind(this);
    this.zsDatePicker.nativeElement.stringToDate = this.stringToDate.bind(this);
  }

  validate() {
    if (this.validationFunction) {
      const message = this.validationFunction(this.selectedDate);
      if (message && message.length > 0) {
        this.errorState = true;
        this.inputClass = this.errorClass;
        this.errorMessage = message;
        this.errorStateChange.emit(this.errorState);
      } else {
        this.resetSelect();
      }
    }
  }

  resetSelect() {
    this.inputClass = '';
    this.errorMessage = '';
    this.errorState = false;
  }

  configure() {
    if (this.selectedDateValue) {
      setTimeout(() => {
        this.zsDatePicker.nativeElement.setDate(this.selectedDateValue);
      });
    }
  }

  ngOnChanges(val) {
    if (val['selectedDate'] && val['selectedDate'].currentValue) {
      this.selectedDate = this.datepipe.transform(val.selectedDate.currentValue, this.environment.dateTimeFormat.dateFormats);
      this.selectedDateValue = this.ddTransform.getTransformedDate(this.selectedDate, this.environment.dateTimeFormat.dateFormats);
      this.configure();
    }
  }

  ngOnInit() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription = this._formValidationService.getMessage().subscribe(
        message => {
          this.touched = true;
          this.validate();
          this.formValidationCallbackFunction(this.errorState);
        }
      );
    }
    if (this.setDefaultDate) {
      if (!this.selectedDate) {
        this.selectedDate = this.datepipe.transform(new Date(), this.environment.dateTimeFormat.dateFormats);
      }
      this.selectedDateValue = this.ddTransform.getTransformedDate(this.selectedDate, this.environment.dateTimeFormat.dateFormats);
    }
    if (!this.minDate) {
      const minDate = new Date();
      minDate.setDate(minDate.getDate());
      this.minDate = this.datepipe.transform(minDate, 'MM/dd/yyyy');
    }
    // this.minDateValue = new Date(this.minDate);
    this.configure();
  }

  dateToString(date: Date) {
    return this.datepipe.transform(date.toLocaleDateString('en-US').replace(/[^ -~]/g, ''),
      this.environment.dateTimeFormat.dateFormats);
  }

  stringToDate(str: String) {
    return this.ddTransform.getTransformedDate(str, this.environment.dateTimeFormat.dateFormats);
  }

  beforeOpen() {
    this.touched = true;
  }

  onChange(event) {
    if (event.target?.value) {
      this.selectedDateValue = this.zsDatePicker.nativeElement.dateValue;
      this.selectedDate = this.datepipe.transform(this.selectedDateValue, this.environment.dateTimeFormat.dateFormats);
      const value = this.zsDatePicker.nativeElement.value;
      if (value !== 'Invalid Date') {
        const dateValue = this.datepipe.transform(this.selectedDateValue, 'MM/dd/yyyy');
        this.selectedDateChange.emit(dateValue);
      }
    } else {
      this.selectedDateValue = null;
      this.selectedDateChange.emit(null);
    }
    this.validate();
  }

  ngOnDestroy() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription.unsubscribe();
    }
  }
}
