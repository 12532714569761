<ag-grid-angular 
    class="grid-class ag-theme-alpine zs-master-style" 
    [ngStyle]="style" [columnDefs]="colDefs"
    [rowData]="data" [suppressCellSelection]="true"
    [frameworkComponents]="frameworkComponents"
    [rowHeight]="rowHeight" 
    [headerHeight]="headerHeight"
    [overlayNoRowsTemplate]="noRowMessage === '' ? noRowsToShowLabel : noRowMessage"
    [suppressFieldDotNotation]="suppressFieldDotNotation"
    [loadingOverlayComponent]="loadingOverlayComponent">
</ag-grid-angular>