<zs-card class="zs-card" class="left-arrow">
  <header>
    <div>
      <span>{{nodedata.name}}</span>
      <span class="zs-icon zs-card-icon {{ nodedata.status == 'Completed' ? 'zs-icon-check-success': nodedata.status == 'Running' ? '' : 'zs-icon-error'}}"></span>
    </div>
  </header>
  <section>
    <app-zs-progress-bar  
        [value]="1" [status]="nodedata.status" [label]="false" [animate]="false">
    </app-zs-progress-bar>
    <div class="message">
      <div *ngIf="nodedata.status == 'Completed'" class="success-message">
        <span>Succeeded without errors</span>
      </div>
      <div *ngIf="nodedata.status == 'Running'" class="running-message">
        <span>Running - {{nodedata.completion}}% completed</span>
      </div>
      <div *ngIf="nodedata.status == 'Failed'" class="error-message">
        <span>Failed</span>
      </div>
    </div>
    <div class="view-error-link"  *ngIf="nodedata.status == 'Failed'">
        <a>View Errors</a>
      </div>
  </section>
  <footer> 
    <span *ngIf="nodedata.status == 'Completed'">Completed</span>
    <span *ngIf="nodedata.status != 'Completed'">Avg. time to complete</span>
    <span class="execution-time">{{nodedata.time}}</span> </footer>
</zs-card>
<!-- dataTransfer : { name: 'CONFIG DATA LOAD', status: 'Success', completion: '100', time: '1 hr ago', childs: []},
// dataLanding: ['DQM'], -->