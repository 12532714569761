<div class="zs-master-style zs-display-flex zs-flex-direction-column">
    <label *ngIf="label" class="timepicker-label">
        {{label}}
    </label>
    <p-is-time-picker class="zs-margin-0 zs-timepicker-element zs-action-field"
    #zsTimePicker
    [disabled]="isDisabled?true:null" [value]="time"
    format24="{{ isFormat24 }}"
    [step]="step"
    (change)="onChange($event)"
    (beforeopen)="beforeOpen($event)"
    [attachToBody]=false
    [ngStyle]="customStyle"
    ></p-is-time-picker>
</div>
