import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { StepperStepStatusConstant } from './../../shared/constants';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class StepperComponent implements OnInit {

  @Input() dataSource = [];
  stepperStepStatusConstant = StepperStepStatusConstant;
  constructor() { }

  ngOnInit(): void {
  }
}
