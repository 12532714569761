<div class="multi-select">
  <div>
    <h3 class="zs-margin-2-2-1-2  filter-range">{{filterRangeLabel}}</h3>
    <div class="zs-margin-1-2-0-2 zs-padding-1-1-1-1">
      <p class="date-text">{{startdateLabel}}</p>
      <app-zs-date-picker (selectedDateChange)="startDateChange($event)" [selectedDate]="startDate" [flippable]="false"
        class="calander-icon-color"
        [customStyle]="{ width: '16.875em' }" [minDate]="minDate">
      </app-zs-date-picker>
    </div>

    <div class="zs-margin-1-2-2-2 zs-padding-1-1-1-1">
      <p class="date-text">{{enddateLabel}}</p>
      <app-zs-date-picker (selectedDateChange)="endDateChange($event)" [selectedDate]="endDate" [flippable]="false" 
        class="calander-icon-color"
        [customStyle]="{ width: '16.875em' }" [minDate]="minDate">
      </app-zs-date-picker>
    </div>
    <div class="zs-row">
      <span>
        <button (click)="onApply()"
          class="zs-button zs-button-action zs-border-rounded-0 zs-margin-2-2-2-0 apply-btn">{{applyLabel}}</button>
      </span>
      <span>
        <button (click)="onReset()" class="zs-button zs-border-rounded-0 zs-margin-2-1-2-1 reset-btn">{{resetLabel}}</button>
      </span>
    </div>
  </div>
</div>