<div class="stepper">
    <div class="zs-stepper">
        <div *ngFor="let step of dataSource" class="zs-step">
            <span *ngIf="step.stepStatus === this.stepperStepStatusConstant.COMPLETED" class="zs-step-milestone success">
                <span class="success-color zs-bg-standard zs-icon zs-size-l zs-icon-check-circle-fill"></span>
            </span>
            <span *ngIf="step.stepStatus === this.stepperStepStatusConstant.FAILED || step.stepStatus === this.stepperStepStatusConstant.CANCELLED ||
            step.stepStatus === this.stepperStepStatusConstant.CANCEL_PENDING || step.stepStatus === this.stepperStepStatusConstant.INTERRUPTED" class=" zs-step-milestone success">
                <span class="failed-color zs-bg-standard zs-icon zs-size-l zs-icon-close-circle-fill"></span>
            </span>
            <span *ngIf="step.stepStatus === this.stepperStepStatusConstant.PENDING  || step.stepStatus === this.stepperStepStatusConstant.RUNNING" class="zs-step-milestone pend">
                <span class="pending-color zs-bg-standard zs-icon zs-size-l zs-icon-subtract-circle-fill"></span>
            </span>

            <div class="zs-step-details">
                <div class="tooltip" [attr.data-title] = "step.value" >{{step.name}}</div>
            </div>
        </div>
    </div>
</div>

