import { Component, OnInit, Input, OnChanges, SimpleChanges, SecurityContext, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizerPipe } from 'src/app/shared/custom-pipes/dom-sanitizer-pipe';
import { DomSanitizerConstants } from 'src/app/shared/constants/security-attacks-constants';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { ViewEncapsulation } from '@angular/core';
import { common_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';
import { TranslateService } from '@ngx-translate/core';
import { CustomLoadingOverlayComponent } from 'src/app/custom-loading-overlay/custom-loading-overlay.component';

@Component({
  selector: 'app-zs-grid',
  templateUrl: './zs-grid.component.html',
  styleUrls: ['./zs-grid.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ZsGridComponent implements OnChanges {

  @Input() id = '';
  @Input() data: any;
  @Input() columnNameList: any[];
  @Input() style: string;
  @Input() rowHeight = 50;
  @Input() headerHeight = 50;
  @Input() isStickyHeader: Boolean = false;
  @Input() noRowMessage = '';
  @Input() suppressFieldDotNotation = false;
  @ViewChild('zsDataGrid') zsDataGrid: ElementRef;
  commonLabels = common_labels;
  translatedLabels: any;
  colDefs: any;
  rowData: any;
  gridApi: any;
  noRowsToShowLabel: string;
  loadingOverlayComponent: string;
  frameworkComponents: { customLoadingOverlay: typeof CustomLoadingOverlayComponent; };
 
  constructor(private _xssSafePipe: DomSanitizerPipe, private _translate: TranslateService) {
    this.translatedLabels = this._translate.instant(
      [
        this.commonLabels.NO_ROWS_TO_SHOW
      ]
    );
    this.noRowsToShowLabel = this.translatedLabels[this.commonLabels.NO_ROWS_TO_SHOW];
    this.loadingOverlayComponent = 'customLoadingOverlay';
     // for adding custom filter to Ag Grid
     this.frameworkComponents = {
      customLoadingOverlay: CustomLoadingOverlayComponent,
    };
  }

  setColDefs() {
    this.colDefs = [];
    if (this.columnNameList !== undefined) {
        this.columnNameList.forEach((element: any) => {
            const colList = {
              [AgGridColumnDefsConstants.HEADER_NAME] :
              `${element}`,
              [AgGridColumnDefsConstants.FIELD] :
              element,
              [AgGridColumnDefsConstants.SUPPRESS_MENU] : true,
              [AgGridColumnDefsConstants.MIN_WIDTH] : 150,
              [AgGridColumnDefsConstants.FLEX]: 1,
              [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
              [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: this.getCellTooltip(),
              [AgGridColumnDefsConstants.HEADER_TOOLTIP]: element
            };
          this.colDefs.push(colList);
        });
        this.frameworkComponents = {
          customLoadingOverlay: CustomLoadingOverlayComponent,
        };
    }
  }

  getCellTooltip() {
    return (cellParams) => cellParams.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnNameList']) {
      this.setColDefs();
    }
  }
}
