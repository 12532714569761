import { Pipe, PipeTransform } from '@angular/core';

export enum ColumnDataTypes {
    STRING,
    INTEGER,
    DATETIME
}

@Pipe({ name: 'orderBy' })
export class OrderrByPipe implements PipeTransform {

    transform(records: Array<any>, args?: any): any {
        let sort_order = 1;
        if (!args.direction) {
            sort_order = -1;
        }
        if (args.property === null || args.property === undefined) {
            return records;
        }
        if (args.dataType === null || args.dataType === undefined) {
            args.dataType = ColumnDataTypes.STRING;
        }
        if (args.dataType === ColumnDataTypes.STRING) {
            return this.stringSort(records, args.property, sort_order);
        } else if (args.dataType === ColumnDataTypes.INTEGER) {
            return this.numberSort(records, args.property, sort_order);
        } else if (args.dataType === ColumnDataTypes.DATETIME) {
            return this.datetimeSort(records, args.property, sort_order);
        }
    }

    stringSort(records, columnName, sortOrder) {
        return records.sort(function (a, b) {
            if (a[columnName] === undefined && b[columnName] === undefined) {
                return 0;
            } else if (a[columnName] === undefined || a[columnName] === null) {
                return 1;
            } else if (b[columnName] === undefined || b[columnName] === null) {
                return -1;
            } else if (a[columnName].toString().toLowerCase() < b[columnName].toString().toLowerCase()) {
                return 1 * sortOrder;
            } else if (a[columnName].toString().toLowerCase() > b[columnName].toString().toLowerCase()) {
                return -1 * sortOrder;
            } else {
                return 0;
            }
        });
    }

    numberSort(records, columnName, sortOrder) {
        return records.sort(function (a, b) {
            if (a[columnName] === undefined && b[columnName] === undefined) {
                return 0;
            } else if (a[columnName] === undefined || a[columnName] === null) {
                return 1;
            } else if (b[columnName] === undefined || b[columnName] === null) {
                return -1;
            } else if (Number(a[columnName]) < Number(b[columnName])) {
                return 1 * sortOrder;
            } else if (Number(a[columnName]) > Number(b[columnName])) {
                return -1 * sortOrder;
            } else {
                return 0;
            }
        });
    }

    datetimeSort(records, columnName, sortOrder) {
        return records.sort(function (a, b) {
            if (a[columnName] === undefined && b[columnName] === undefined) {
                return 0;
            } else if (a[columnName] === undefined || a[columnName] === null) {
                return 1;
            } else if (b[columnName] === undefined || b[columnName] === null) {
                return -1;
            } else if (new Date(a[columnName]) < new Date(b[columnName])) {
                return 1 * sortOrder;
            } else if (new Date(a[columnName]) > new Date(b[columnName])) {
                return -1 * sortOrder;
            } else {
                return 0;
            }
        });
    }
}
