import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DdDQMDataTypeConstants, DdDqmConstants } from 'src/app/shared/constants/driver-data-dqm-tooltip-messages-constants';
import {
  DriverDataDetailConstants,
  EDITABLE_TABLE_OPTION,
  SortConstants,
} from 'src/app/shared/constants';
import {
  EditableTableService,
  FilterOpenService,
} from '../table-column-filters/table-column-filters.service';
import {
  HeaderType,
  Headers,
  SelectedFilterOptionEmitData,
  ValueChange,
} from 'src/app/revo-core/table-column-filters/table-column-filters-models';
import { common_labels, revo_core_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';

import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { AppliedFiltersData } from '../table-column-filters/table-column-filters-models';
import { AutocompleteSelectCellEditor } from 'ag-grid-autocomplete-editor';
import { CustomLoadingOverlayComponent } from 'src/app/custom-loading-overlay/custom-loading-overlay.component';
import { EditableCallbackParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ZsEditableTableSelectComponent } from '../zs-editable-table-select/zs-editable-table-select.component';
import { ZsMultiSelectDropdownComponent } from '../zs-multi-select-dropdown/zs-multi-select-dropdown.component';
import Utils from 'src/app/app.utils';
import { debounceTime } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';

@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class EditableTableComponent implements OnInit, OnChanges, OnDestroy {
  loadingOverlayComponent: string;
  noRowsToDisplayMessage: any;
  environment: EnvironmentConfig;

  constructor(
    private _editableTableService: EditableTableService,
    private filterOpenService: FilterOpenService,
    private _translate: TranslateService,
    private _config: AppConfig,
  ) {
    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.translatedLabels = this._translate.instant(
      [
        this.dqmMessages.OR,
        this.commonLabels.NO_RECORDS_TO_DISPLAY
      ]
    );
    this.noRowsToDisplayMessage = this.translatedLabels[this.commonLabels.NO_RECORDS_TO_DISPLAY],
    this.noRowsTemplate = Utils.generateNoRowsHtml(this.noRowsToDisplayMessage);
    this.orLabel = this.translatedLabels[this.dqmMessages.OR];
  }
  @ViewChild(ZsMultiSelectDropdownComponent)
  autoComplete: ZsMultiSelectDropdownComponent;
  @Input() headers: Array<Headers>;
  @Input() data: any;
  @Input() IsAddCheckbox: Boolean;
  @Input() RowIdKey: string;
  @Input() tableName: string;
  @Input() autoCompleteDataService: Function;
  @Input() selectedOptionValues: Array<AppliedFiltersData>;
  @Input() CheckedCheckboxIds: any;
  @Input() isActiveVersion: boolean;
  @Output() autoCompleteForeignKeyDependentValues = new EventEmitter();
  @Output() loadMoreFilterDataValues = new EventEmitter();
  @Output() selectedFilterOption = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @Output() afterHeaderSync = new EventEmitter();
  @Output() columnSorted = new EventEmitter();

  isEditableKey = 'isEditable';
  headerType = HeaderType;
  headerDisplayNameShowLimit =
    DriverDataDetailConstants.headerDisplayNameShowLimit;
  columnDefs = [];
  rowData = [];
  defaultColDef = {};
  frameworkComponents: any;
  gridOptions = {};
  gridApi: any;
  filterSelectedOptionsSubscription: Subscription;
  clearAllFilterSubscription: Subscription;
  filterSelectedOptionSubscription: Subscription;
  lastColumnSorted = '';
  loadData = { value: false };
  isRowSelectable: any;
  dqmMessages = revo_core_labels.DD_OBJECT_DQM_MESSAGES;
  commonLabels = common_labels;
  orLabel: string;
  translatedLabels: any;
  noRowsTemplate: string;

  ngOnInit() {
    // for adding custom filter to Ag Grid
    this.frameworkComponents = {
      customFilter: ZsMultiSelectDropdownComponent,
      customLoadingOverlay: CustomLoadingOverlayComponent,
      customSelectDropdown: ZsEditableTableSelectComponent
    };

    this.isRowSelectable = function (rowNode) {
      return rowNode.data.isEditable;
    };

    this.defaultColDef = {
      [AgGridColumnDefsConstants.MENU_TABS]: [
        AgGridColumnDefsConstants.FILTER_MENU_TAB,
        AgGridColumnDefsConstants.GENERAL_MENU_TAB,
        AgGridColumnDefsConstants.COLUMNS_MENU_TAB,
      ],
      [AgGridColumnDefsConstants.FLEX]: 1,
      [AgGridColumnDefsConstants.MIN_WIDTH]: 202,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
    };

    // Let the grid know which columns and what data to use
    this.gridOptions = {
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      [AgGridColumnDefsConstants.STOP_EDITING_WHEN_CELLS_LOSE_FOCUS]: true,
      [AgGridColumnDefsConstants.SINGLE_CLICK_EDIT]: true,
      [AgGridColumnDefsConstants.FRAMEWORK_COMPONENTS]:
        this.frameworkComponents,
      [AgGridColumnDefsConstants.ROW_SELECTION]:
        AgGridColumnDefsConstants.MULTIPLE,
      [AgGridColumnDefsConstants.ROW_MULTIPLE_SELECT_WITH_CLICK]: true,
      [AgGridColumnDefsConstants.SUPPRESS_ROW_CLICK_SELECTION]: true,
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.IS_ROW_SELECTABLE]: this.isRowSelectable,
    };
    this.environment = this._config.getConfigObject();

    // subscriptions to handle filter events
    this.filterSelectedOptionsSubscription = this.filterOpenService
    .getSelectedOptions().pipe(
      debounceTime(this.environment.debounceInterval)
      )
    .subscribe((selectedOptionsObject) => {
      selectedOptionsObject.filterText.length
        this.loadMoreFilterData(selectedOptionsObject);
      });

    this.clearAllFilterSubscription = this.filterOpenService
      .getClearAllFilterStatus()
      .subscribe((clearFilterObject) => {
        this.clearFilters(clearFilterObject);
      });

    this.filterSelectedOptionSubscription = this.filterOpenService
      .getLoadFilterDataStatus()
      .subscribe((selectedOption) => {
        this.getSelectedFilterOption(selectedOption);
      });
  }

  generateDqmMessagesFromDqmData(dqmChecks) {
    const messageList = [];
    if (dqmChecks !== null) {
      let isMinMaxBoth = false;
      // Not Null check
      if (dqmChecks.hasOwnProperty(DdDqmConstants.NOT_NULL) && dqmChecks[DdDqmConstants.NOT_NULL]) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.THIS_COLUMN_CAN_NOT_BE_EMPTY));
      }

      // MIN and MAX both
      if (dqmChecks.hasOwnProperty(DdDqmConstants.MIN) &&
        dqmChecks.hasOwnProperty(DdDqmConstants.MAX)) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.MIN_MAX_BOTH,
          {
            'max': dqmChecks[DdDqmConstants.MAX],
            'min': dqmChecks[DdDqmConstants.MIN]
          }
        ));
        isMinMaxBoth = true;
      }

      // Data type
      if (dqmChecks.hasOwnProperty(DdDqmConstants.TYPE) && dqmChecks[DdDqmConstants.TYPE]) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.ACCEPTED_TYPE,
          {
            'type': DdDQMDataTypeConstants.hasOwnProperty(dqmChecks[DdDqmConstants.TYPE].toUpperCase()) ?
            DdDQMDataTypeConstants[dqmChecks[DdDqmConstants.TYPE].toUpperCase()] : dqmChecks[DdDqmConstants.TYPE]
          }
        ));
      }

      // Timestamp
      if (dqmChecks.hasOwnProperty(DdDqmConstants.TIMESTAMP) && dqmChecks[DdDqmConstants.TIMESTAMP]) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.TIMESTAMP_FORMAT,
          {
            'format': dqmChecks[DdDqmConstants.TIMESTAMP]
          }
        ));
      }

      // Inclusion
      if (dqmChecks.hasOwnProperty(DdDqmConstants.INCLUSION) && dqmChecks[DdDqmConstants.INCLUSION]) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.INCLUSION,
          {
            'inclusions': dqmChecks[DdDqmConstants.INCLUSION]
          }
        ));
      }

      // Exclusion
      if (dqmChecks.hasOwnProperty(DdDqmConstants.EXCLUSION) && dqmChecks[DdDqmConstants.EXCLUSION]) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.EXCLUSION,
          {
            'exclusions': dqmChecks[DdDqmConstants.EXCLUSION]
          }
        ));
      }

      // build range message
      if (dqmChecks.hasOwnProperty(DdDqmConstants.RANGE) && dqmChecks[DdDqmConstants.RANGE].length > 0) {
        const dqmRangeList = [];
        for (const dqmRange of dqmChecks[DdDqmConstants.RANGE]) {
          dqmRangeList.push(this._translate.instant(
            this.dqmMessages.RANGE_INDIVIDUAL_MESSAGE,
            {
              'max': dqmRange[DdDqmConstants.MAX],
              'min': dqmRange[DdDqmConstants.MIN]
            }
          ));
        }
        if (dqmRangeList.length > 0) {
          messageList.push('&#8226; ' + this._translate.instant(
            this.dqmMessages.RANGE_MAIN
          ) + dqmRangeList.join(' ' + this.orLabel + ' '));
        }
      }

      // build length messages
      if (dqmChecks.hasOwnProperty(DdDqmConstants.LENGTH)) {
        const lengthMessage = this.getLengthDQMMessage(dqmChecks[DdDqmConstants.LENGTH]);
        if (lengthMessage !== '') {
          messageList.push('&#8226; ' + lengthMessage);
        }
      }

      // Max only or Min only
      if (dqmChecks.hasOwnProperty(DdDqmConstants.MAX) && !isMinMaxBoth) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.MAX_ONLY,
          {
            'max': dqmChecks[DdDqmConstants.MAX]
          }
        ));
      } else if (dqmChecks.hasOwnProperty(DdDqmConstants.MIN) && !isMinMaxBoth) {
        messageList.push('&#8226; ' + this._translate.instant(
          this.dqmMessages.MIN_ONLY,
          {
            'min': dqmChecks[DdDqmConstants.MIN]
          }
        ));
      }
    }
    return messageList.join('\n');
  }

  getLengthDQMMessage(dqmLengthChecks) {
    /*
      1) > and <=
      2) >= and <
      3) >= and <=
      4) > and <
      5) =
      6) !=
      7) >
      8) >=
      9) <
      10) <=
    */
    let lengthMessage = '';
    if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.GREATER_THAN) &&
    dqmLengthChecks.hasOwnProperty(DdDqmConstants.LESS_THAN_EQUAL_TO)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.GREATER_THAN_LESS_THAN_EQUALS_TO,
        {
          'greaterThan': dqmLengthChecks[DdDqmConstants.GREATER_THAN],
          'LessThan': dqmLengthChecks[DdDqmConstants.LESS_THAN_EQUAL_TO]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.GREATER_THAN_EQUAL_TO) &&
    dqmLengthChecks.hasOwnProperty(DdDqmConstants.LESS_THAN)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.GREATER_THAN_EQUAL_TO_LESS_THAN,
        {
          'greaterThan': dqmLengthChecks[DdDqmConstants.GREATER_THAN_EQUAL_TO],
          'LessThan': dqmLengthChecks[DdDqmConstants.LESS_THAN]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.GREATER_THAN_EQUAL_TO) &&
    dqmLengthChecks.hasOwnProperty(DdDqmConstants.LESS_THAN_EQUAL_TO)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.GREATER_THAN_EQUAL_TO_LESS_THAN_EQUALS_TO,
        {
          'greaterThan': dqmLengthChecks[DdDqmConstants.GREATER_THAN_EQUAL_TO],
          'LessThan': dqmLengthChecks[DdDqmConstants.LESS_THAN_EQUAL_TO]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.GREATER_THAN) &&
    dqmLengthChecks.hasOwnProperty(DdDqmConstants.LESS_THAN)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.GREATER_THAN_LESS_THAN,
        {
          'greaterThan': dqmLengthChecks[DdDqmConstants.GREATER_THAN],
          'LessThan': dqmLengthChecks[DdDqmConstants.LESS_THAN]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.EQUAL_TO)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.LENGTH_EQUALS_TO,
        {
          'chars': dqmLengthChecks[DdDqmConstants.EQUAL_TO]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.NOT_EQUAL_TO)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.LENGTH_NOT_EQUALS_TO,
        {
          'chars': dqmLengthChecks[DdDqmConstants.NOT_EQUAL_TO]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.GREATER_THAN)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.GREATER_THAN_LENGTH_ONLY,
        {
          'chars': dqmLengthChecks[DdDqmConstants.GREATER_THAN]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.LESS_THAN)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.LESS_THAN_LENGTH_ONLY,
        {
          'chars': dqmLengthChecks[DdDqmConstants.LESS_THAN]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.LESS_THAN_EQUAL_TO)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.LESS_THAN_EQUAL_TO_LENGTH_ONLY,
        {
          'chars': dqmLengthChecks[DdDqmConstants.LESS_THAN_EQUAL_TO]
        }
      );
    } else if (dqmLengthChecks.hasOwnProperty(DdDqmConstants.GREATER_THAN_EQUAL_TO)) {
      lengthMessage = this._translate.instant(
        this.dqmMessages.GREATER_THAN_EQUAL_TO_LENGTH_ONLY,
        {
          'chars': dqmLengthChecks[DdDqmConstants.GREATER_THAN_EQUAL_TO]
        }
      );
    }
    return lengthMessage;
  }

  getHeaderComponentParams(header: Headers) {
    const asteriskSpanElement = header.IsRequired
      ? ' <span style="color:#d7826e; padding-left: 0.3em;">*</span> '
      : '';

    const primaryKeyIcon = header.IsPrimaryKey
      ? '<span class="zs-icon zs-icon-table-key" style="padding-right: 0.3em;"></span>'
      : '';

    const dqmChecksInfoIcon = header.isDqmCheck
      ? `<span class="zs-icon zs-size-large zs-icon-info"
      title="${this.generateDqmMessagesFromDqmData(header.DqmChecks)}" style="padding-right: 0.3em; padding-left: 0.3em"></span>`
      : '';

    return (
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      primaryKeyIcon +
      `    <span ref="eText" class="ag-header-cell-text" title="${header.DisplayName}" role="columnheader"></span>` +
      asteriskSpanElement +
      dqmChecksInfoIcon +
      '    <span ref="eFilter" class="ag-icon ag-header-icon ag-filter-icon"></span>' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
      '  </div>' +
      '</div>'
    );
  }

  ngOnChanges(changes): void {
    // Specify the columns
    if (changes.headers && changes.headers.currentValue) {
      const $this = this;

      this.columnDefs = [];
      const emptyColDef = {
        [AgGridColumnDefsConstants.FIELD]: '',
        [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
        [AgGridColumnDefsConstants.MAX_WIDTH]: 45,
        [AgGridColumnDefsConstants.FLEX]: 0,
        [AgGridColumnDefsConstants.CHECKBOX_SELECTION]: true,
        [AgGridColumnDefsConstants.HEADER_CHECKBOX_SELECTION]: true,
        [AgGridColumnDefsConstants.HIDE]: (this.isActiveVersion === undefined || this.isActiveVersion === true) ? false : true,
        [AgGridColumnDefsConstants.SUPPRESS_COLUMN_TOOL_PANEL]: true,
      };
      this.columnDefs.push(emptyColDef);
      for (const header of this.headers) {
        if (this.gridApi) {
          this.gridApi.destroyFilter(header.Name);
        }

        if (header.IsVisible) {
          let headerName = header.DisplayName;
          if (headerName.length > this.headerDisplayNameShowLimit) {
            headerName =
              headerName.slice(0, this.headerDisplayNameShowLimit) + '...';
          }
          const colDef = {
            headerName: headerName,
            field: header.Name,
            filter: header.IsFilterable ? 'customFilter' : false,
            filterParams: () => {
              this.loadData.value = true;
              return {
                loadData: this.loadData,
                filterClass: 'subcategory-field',
                isFilter: header.IsFilterable,
                dataSource: header.FilterValues,
                displayLabelKey: 'Value',
                minOptionsForSearch: 3,
                filterId: header.Name,
                filterName: header.DisplayName,
                tableName: $this.tableName,
                showClearAllOption: true,
                PreSelectedOptionValues: $this.selectedOptionValues,
              };
            },
            sortable: true,
            sortingOrder: ['asc', 'desc', null],
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
              return 0;
            },
            tooltipValueGetter: (cellParams) => {
              return cellParams.value;
            },
            headerComponentParams: {
              template: this.getHeaderComponentParams(header),
            },
          };
          colDef[AgGridColumnDefsConstants.EDITABLE] = function (
            cellParams: EditableCallbackParams
          ) {
            return cellParams.data.isEditable && header.IsEditable;
          };
          colDef[AgGridColumnDefsConstants.CELL_STYLE] = function (cellParams) {
            const style = { height: '4em' };
            if (header.Type === $this.headerType.Select) {
              return { ...style, paddingTop: '0.32em', paddingBottom: '0.32em'};
            }
            return style;
          };
          if (header.Type === this.headerType.Select) {
            colDef[AgGridColumnDefsConstants.CELL_RENDERER_SELECTOR] = function (params) {
              const customSelectDropdown = {
                component: 'customSelectDropdown', params: {
                  dataSource: header.DropdownOptions,
                  colId: header.Name,
                }
              };
              return customSelectDropdown;
            };
            colDef[AgGridColumnDefsConstants.EDITABLE] = false;
          } else if (header.Type === this.headerType.Autocomplete) {
            colDef[AgGridColumnDefsConstants.CELL_EDITOR] =
              AutocompleteSelectCellEditor;
            const autoCompleteService = this.autoCompleteDataService;
            colDef[AgGridColumnDefsConstants.CELL_EDITOR_PARAMS] = {
              required: true,
              autocomplete: {
                fetch: (cellEditor, text, update) => {
                  autoCompleteService(text, header.RefferedIDKey, 0, true, update);
                },
                customize: () => { },
              },
            };
            colDef[AgGridColumnDefsConstants.VALUE_FORMATTER] = (params) => {
              if (params.value) {
                return params.value.label || params.value.value || params.value;
              }
              return '';
            };
          } else if (header.Type === this.headerType.Readonly) {
            colDef[AgGridColumnDefsConstants.EDITABLE] = false;
          }
          this.columnDefs.push(colDef);
        }
      }
    }

    if (this.columnDefs && this.columnDefs.length > 0) {
      if (this.gridApi) {
        this.afterHeaderSync.emit();
      }
    }

    if (
      changes.data &&
      changes.data.currentValue &&
      changes.data.currentValue.length > 0
    ) {
      if (this.gridApi) {
        this.afterHeaderSync.emit();
      }
      this.loadData.value = false;
    }
  }

  // when grid gets ready structurally
  onGridReady(gridParams: any) {
    this.gridApi = gridParams.api;
    this.gridApi.setDomLayout('autoHeight');
  }

  onSort($event) {
    console.log($event);
    const columnsSortState = $event.columnApi.getColumnState();
    let columnName = '';
    let columnSortOrder = '';
    for (const column of columnsSortState) {
      if (column.sort) {
        columnName = column.colId;
        columnSortOrder = column.sort;
        break;
      }
    }
    for (const header of this.headers) {
      if (columnName === header.Name) {
        header.SortOrder = columnSortOrder;
        this.columnSorted.emit(header);
        break;
      }
    }
  }

  // when a row gets selected
  onRowSelected(event) {
    const valueChangeModel = new ValueChange();
    valueChangeModel.HeaderName = '';
    valueChangeModel.Id = event.data[this.RowIdKey];
    valueChangeModel.Value = event.node.isSelected();

    this._editableTableService.Add(valueChangeModel);
  }

  // when a cell value gets changed
  onCellValueChanged(event: any) {
    let timeout = 0;
    let value;
    const valueChangeModel = new ValueChange();
    valueChangeModel.HeaderName = event.colDef.field;
    valueChangeModel.Id = event.data[this.RowIdKey];
    if (typeof event.value === 'object') {
      value = event.value.label;
      event.value = value;
      timeout = 2000;
      this.gridApi.showLoadingOverlay();
      const selectedOptionObject = new SelectedFilterOptionEmitData();
      selectedOptionObject.value = value;
      selectedOptionObject.rdh_primary_key = event.data[this.RowIdKey];
      for (const header of this.headers) {
        if (header.Name === event.colDef.field) {
          selectedOptionObject.referenceKeyId = header.RefferedIDKey;
          break;
        }
      }
      this.autocompleteSelectedOption(selectedOptionObject);
    }
    valueChangeModel.Value = event.value;
    this._editableTableService.Add(valueChangeModel);
    setTimeout(() => {
      this.gridApi.setRowData(this.data);
    }, timeout);
  }

  autocompleteSelectedOption($event) {
    this.autoCompleteForeignKeyDependentValues.emit($event);
  }

  getSelectedFilterOption($event) {
    this.selectedFilterOption.emit($event);
  }

  clearFilters($event) {
    this.clearFilter.emit($event);
  }

  loadMoreFilterData($event) {
    this.loadMoreFilterDataValues.emit($event);
  }

  rowDataChanged($event) {
    if (this.gridApi) {
      this.gridApi.forEachNode((node) => {
        if (this.CheckedCheckboxIds.includes(node.data.rdh_primary_key)) {
          node.setSelected(true);
        }
      });
    }
  }

  ngOnDestroy() {
    // unsubscribing subscription to handle filter events
    this.filterSelectedOptionsSubscription.unsubscribe();
    this.clearAllFilterSubscription.unsubscribe();
    this.filterSelectedOptionSubscription.unsubscribe();
  }
}
