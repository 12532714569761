import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';

declare var syncTableWidths: any;
declare var syncStickyHeader: any;
declare var syncStickyHeaderScroll: any;
@Directive({
  selector: '[appStickyHeader]'
})
export class StickyHeaderDirective implements AfterViewInit, OnChanges {

  @Input() syncHeaders: Boolean;
  @Output() afterHeaderSync = new EventEmitter();
  @Output() onSort = new EventEmitter();
  element: any;
  constructor(Element: ElementRef) {
    this.element = Element.nativeElement;
  }

  ngOnChanges(changes): void {
    if (changes.syncHeaders && changes.syncHeaders.currentValue) {
      setTimeout(function () {
        var scrollableTable: any = document.getElementsByClassName('scrollable-table')[0];
        var stickyTable: any = document.getElementsByClassName('sticky-table')[0];
        var stickyContainer = document.getElementsByClassName('sticky-container')[0];
        var scrollableContainer = document.getElementsByClassName('scrollable-container')[0];
        syncTableWidths(scrollableTable, stickyTable);
        syncStickyHeader(scrollableTable, stickyTable, stickyContainer, scrollableContainer);
        syncStickyHeaderScroll(stickyContainer, scrollableContainer)
        this.afterHeaderSync.emit(false);
      }.bind(this), 0)

    }

  }

  headerClicked(event) {
    var target: any = event.target;
    var sortAttr = target.getAttribute('sort');

    var table = document.getElementsByClassName('sticky-table')[0]
    var tdNodes = table.firstElementChild.firstChild.childNodes;
    for (var i = 0; i < tdNodes.length; i++) {
      var node: any = tdNodes[i].firstChild;
      if (node !== null && node.nodeType !== 3 && node.hasAttribute('sort')) {
        node.setAttribute('sort', '');
      }
    }

    let sortOrder = '';
    if (sortAttr === '') {
      sortOrder = 'asc';
    } else if (sortAttr === 'asc') {
      sortOrder = 'desc';
    } else if (sortAttr === 'desc') {
      sortOrder = 'asc';
    }
    target.setAttribute('sort', sortOrder);

    let sortData = {
      name : target.getAttribute('value'),
      dataType : Number(target.getAttribute('dataType')),
      sortOrder: sortOrder
    };

    this.onSort.emit(sortData);
  }

  ngAfterViewInit(): void {

    this.element.classList.add('scrollable-table');

    var theadContent = this.element.children[0].innerHTML;

    var scrollableContainer = this.element.parentElement;
    scrollableContainer.classList.add('scrollable-container');
    scrollableContainer.setAttribute("style", "height:400px;overflow:auto; grid-row-start: 2;");

    var tableWrapper = scrollableContainer.parentElement;

    tableWrapper.setAttribute("style", "position: relative; display: inline-grid; grid-template-rows: min-content 1fr;");

    var stickyHeaderDiv = document.createElement('div');
    stickyHeaderDiv.classList.add('sticky-container', 'table-width');
    stickyHeaderDiv.setAttribute("style", "visibility: visible;overflow-x: hidden;left: 0px;top: 0px;grid-row-start: 1;");

    var stickyHeaderTable = document.createElement('table');
    stickyHeaderTable.classList.add('sticky-table', 'zs-data-table');
    stickyHeaderTable.setAttribute("style", "margin-bottom: 0px;");


    var stickyHeaderTableHead = document.createElement('thead');
    stickyHeaderTableHead.innerHTML = theadContent;

    stickyHeaderTable.appendChild(stickyHeaderTableHead);
    stickyHeaderDiv.appendChild(stickyHeaderTable);
    tableWrapper.insertBefore(stickyHeaderDiv, tableWrapper.firstChild);


    var elements = document.getElementsByClassName("sort");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', function (event) {
        this.headerClicked(event);
      }.bind(this), false);
    }
  }
}
