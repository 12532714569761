import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChartNames } from './chart-constants';
import * as Highcharts from 'highcharts';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import {SingleLineChartCreator, HeatMapCreator, ColumnChartCreator} from './charts-config-creator';
highchartsHeatmap(Highcharts);

@Component({
  selector: 'app-visualization-charts',
  templateUrl: './visualization-charts.component.html',
  styleUrls: ['./visualization-charts.component.less']
})
export class VisualizationChartsComponent implements OnInit {

  highcharts = Highcharts;
  @Input() config;
  @Input() data;
  chartOptions: any;


  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
  if (changes.data || changes.config)  {
      this.setChartConfig();
    }
  }

  ngOnInit() {
  }

  setChartConfig() {
    this.chartOptions = {};
    let chart;
    switch (this.config.chartType) {
      case ChartNames.LINE_GRAPH:
        chart = new SingleLineChartCreator().setChartConfig(this.config, this.data);
        break;
      case ChartNames.HEAT_MAP_GRAPH:
        chart = new HeatMapCreator().setChartConfig(this.config, this.data);
        break;
      case ChartNames.COLUMN_GRAPH:
        chart = new ColumnChartCreator().setChartConfig(this.config, this.data);
        break;
    }
    this.chartOptions = JSON.parse(JSON.stringify(chart));
    this.chartOptions.tooltip = chart.getChartTooltip();
    this.chartOptions.plotOptions.series.dataLabels = chart.getDataLabelsFormatter();
  }
}
