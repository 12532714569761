import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

declare var $: any;
declare var dagre: any;
declare var zs: any;

@Component({
  selector: 'app-zs-flow',
  templateUrl: './zs-flow.component.html',
  styleUrls: ['./zs-flow.component.less']
})
export class ZsFlowComponent implements OnInit, AfterViewInit {
  @Input() jobId;

  data = {
    'nodes': [
      {
        'id': 'start',
        'type': 'start',
        'text': 'Start',
        'w': 400,
        'h': 156
      },
      {
        'id': 'fork1',
        'type': 'start',
        'text': 'Do Something?',
        'w': 400,
        'h': 156
      },
      {
        'id': 'decide1',
        'type': 'start',
        'text': 'Make Decision',
        'w': 400,
        'h': 156
      },
      {
        'id': 'decide2',
        'type': 'start',
        'text': 'Make Decision',
        'w': 400,
        'h': 156
      },
      {
        'id': 'fork2',
        'type': 'start',
        'text': 'Make Decision',
        'w': 400,
        'h': 156
      },
      {
        'id': 'decide3',
        'type': 'start',
        'text': 'Make Decision',
        'w': 400,
        'h': 156
      },
      {
        'id': 'decide4',
        'type': 'start',
        'text': 'Make Decision',
        'w': 400,
        'h': 156
      },
      {
        'id': 'decide5',
        'type': 'start',
        'text': 'Make Decision',
        'w': 400,
        'h': 156
      },
      {
        'id': 'join1',
        'type': 'start',
        'text': 'Do Something',
        'w': 400,
        'h': 156
      },
      {
        'id': 'join2',
        'type': 'start',
        'text': 'Do Something',
        'w': 400,
        'h': 156
      }
    ],
    'edges': [
      {
        'id': 1,
        'source': 'start',
        'target': 'fork1'
      },
      {
        'id': 2,
        'source': 'fork1',
        'target': 'decide1'
      },
      {
        'id': 3,
        'source': 'fork1',
        'target': 'decide2'
      },
      {
        'id': 4,
        'source': 'decide1',
        'target': 'join1'
      },
      {
        'id': 5,
        'source': 'decide2',
        'target': 'fork2'
      },
      {
        'id': 6,
        'source': 'fork2',
        'target': 'decide3'
      },
      {
        'id': 7,
        'source': 'fork2',
        'target': 'decide4'
      },
      {
        'id': 8,
        'source': 'fork2',
        'target': 'decide5'
      },
      {
        'id': 9,
        'source': 'decide3',
        'target': 'join2'
      },
      {
        'id': 10,
        'source': 'decide4',
        'target': 'join2'
      },
      {
        'id': 11,
        'source': 'decide5',
        'target': 'join2'
      },
      {
        'id': 12,
        'source': 'join2',
        'target': 'join1'
      }
    ]
  };
  constructor() { }

  ngOnInit() {
    console.log(this.jobId);
  }

  ngAfterViewInit() {
    this.arrange();
    for (let i = 0; i < this.data.edges.length; i++) {
      let source, target;
      if (this.data.edges[i]['target'].indexOf('join') >= 0) {
        source = this.data.edges[i]['target'];
        target = this.data.edges[i]['source'];
      } else {
        source = this.data.edges[i]['source'];
        target = this.data.edges[i]['target'];
      }
      const nodeConnector = new zs.nodeConnectorElement();
      nodeConnector.classList.add('zs-node-connector');
      nodeConnector.from = '#' + source;
      nodeConnector.to = '#' + target;
      nodeConnector.within = '.flow-canvas';
      document.body.appendChild(nodeConnector);
    }
  }

  arrange() {
    const g = new dagre.graphlib.Graph();
    g.setGraph({ rankdir: 'LR' });
    g.setDefaultEdgeLabel(function () {
      return {};
    });
    $.each(this.data.nodes, function (index, value) {
      g.setNode(value.id, {
        width: 240,
        height: 195
      });
    });
    const edges = this.data.edges;
    for (let i = 0; i < edges.length; i++) {
      const c = edges[i];
      g.setEdge(c.source, c.target);
    }
    // calculate the layout (i.e. node positions)
    dagre.layout(g);
    $.each(this.data.nodes, function (index, value) {
      $('#' + value.id).css('left', g.node(value.id).x + 'px');
      $('#' + value.id).css('top', g.node(value.id).y + 'px');
      $('#' + value.id).css('position', 'absolute');
    });
  }

}
