<div >
  <div class="zs-custom-scrollbar zs-master-style editable">
    <ag-grid-angular
      #agGrid
      id="myGrid"
      class="ag-theme-alpine editable-ag-grid"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [rowData]="data"
      (gridReady)="onGridReady($event)"
      (rowSelected)="onRowSelected($event)"
      (rowDataChanged)="rowDataChanged($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      (sortChanged)="onSort($event)"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [overlayNoRowsTemplate]="noRowsTemplate"
    ></ag-grid-angular>
</div>

