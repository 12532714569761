import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';
import { common_labels } from '../shared/constants/ui_labels_translation_mapping';

@Component({
  selector: 'app-custom-loading-overlay',
  templateUrl: './custom-loading-overlay.component.html',
  styleUrls: ['./custom-loading-overlay.component.less']
})
export class CustomLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  private params: ILoadingOverlayParams;
  commonLabels = common_labels;
  translatedLabels: any;
  loadingLabel: any;
  
  constructor( private _translate: TranslateService) { 
    this.translatedLabels = this._translate.instant(
      [
        this.commonLabels.YOUR_RESULTS_ARE_LOADING
      ]
    );
    this.loadingLabel = this.translatedLabels[this.commonLabels.YOUR_RESULTS_ARE_LOADING];
  }

  agInit(params: ILoadingOverlayParams): void {
    this.params = params;
}

}
