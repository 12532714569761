<div class="textbox-wrapper">
  <div class="zs-master-style text-field" [ngClass]="{ 'auto-suggest': autocompleteField && dataSource }">
    <label *ngIf="!hideLabel" class="text-field-label" [ngClass]="labelInverse ? 'zs-text-inverse': 'zs-text-headline'">
      <span *ngIf="!showLabelTooltip; else tooltipHint">{{ hint }}</span>
      <ng-template #tooltipHint>
        {{hint | applyEllipses:hintEllipsisConstant}}
        <zs-tooltip *ngIf="hint.length > hintEllipsisConstant" handle-transform class="tooltip-width">
          <zs-tooltip-content class="zs-size-xs tooltip-content">
            {{hint}}
          </zs-tooltip-content>
        </zs-tooltip>
      </ng-template>

      <!-- <span *ngIf="!required && enableSearch !== 'true' && showOptionalText" class="zs-font-weight-normal">
        <i translate> {{ commonlabels.OPTIONAL_IN_PARANTHESIS }} </i>
      </span> -->
      <span *ngIf="infoLabel !== ''" id="info-icon" class="zs-icon zs-icon-info zs-size-l info-icon"></span>
      <zs-tooltip *ngIf="infoLabel !== ''" [handleTransform]="true" position="bottom-left" class="zs-size-s">
        {{infoLabel}}
      </zs-tooltip>
    </label>
    <p-is-zs-field #actionField [ngClass]="{ inputClass: true}" [ngStyle]="customStyle"
      class="zs-action-field zs-master-style icon field background-color-class" [type]="inputType" autocomplete="off"
      [icon]="enableSearch === 'true' ? 'search' : null" [clear]="enableSearch === 'true' ? '' : null"
      [value]="modelValue" [disabled]="disabled" (click)="onClick()" [placeholder]="placeholder"
      (focusout)="focusOutEvent($event)" (hint)="showHelperText()" [helperText]="helperText"
      [hintIcon]="infoIconMessage ? 'info' : null" [validationMessageText]="errorMessage"
      [validationMessageType]="errorClass" (input)="onTextChange($event.target.value)"></p-is-zs-field>

    <ul #menu *ngIf="autocompleteField && dataSource" class="zs-menu auto-suggest-menu" (changed)="menuOpened()"
      [ngStyle]="autoselectStyle">
      <ng-container *ngIf="{
          value:
            (dataSource | filterTable: [globalParamLabels.DISPLAY_LABEL]:this.searchText:false)
        } as filterData">
        <ng-container *ngFor="let menu of filterData.value">
          <li (mousedown)="selectValueFromMenu(menu)">
            <span>{{ final + menu.displayLabel }}</span>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>